import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Reviews = () => (
  <Layout>
    <SEO title="Customer Reviews" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
            Ex-Smokers can't stop raving about Cravv<sup>®</sup>
            </h3>
            <a class="btn_alt" href="#"><i class="fa fa-angle-down"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx1 center">
          <h4>
            "I really liked the product and it helped me to quit smoking with minimal side effects. I found the price to be reasonable as well."
          </h4>
          <h6>
          – Janet from Cambridge, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "I have tried many products to quit smoking and this finally worked for me. I didn't even have any side effects"
          </h4>
          <h6>
          – George from Pickering, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "Out of everything I've tried, and I've tried everything, THIS is working."
          </h4>
          <h6>
          – Michelle from Kitchener-Waterloo, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "I also posted about Cravv on my Facebook page, my neighbour saw it and ordered, and now he's a non-smoker (this is a guy whose tried everything in the past, including Champix). Thank you for bringing this to market, I'm happy to promote and share in any way!"
          </h4>
          <h6>
          – Nurse from Family Health Team, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "I have tried many products to quit smoking and this finally worked for me. I didn't even have any side effects"
          </h4>
          <h6>
          – George from Pickering, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "Hi there! I usually don’t pm Facebook pages however I had to offer some feedback as a new consumer of Cravv. I’ve been nicotine dependent for years. I’ve had addiction transfer from smoking to gums, to patches, back to smoking and so on. I went into our local pharmacy that’s in a small town and I opened up about my addiction and how badly I just wanted it to stop. He recommended Cravv. I didn’t believe him. I was hesitant. He told me that I could do it and that he has had successfully seen heavy smokers over the past two weeks completely quit by using this. Anyhow to make a long story short I’ve only been using this product for a short time and I’m proud to say I’ve had NO cravings. No mood swings. Nothing. I feel good! Thank you very much for providing this product at a reasonable price!!!"
          </h4>
          <h6>
          – Kristen from Ayr, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "We gave 2 bottles to heavy smokers ( free of charge (good customers) with the promise if it worked they would return and pay for it. If it didn’t work, it was free.) Both returned and said it worked well WHEN they took it but have still cut their smoking immensely with the hope to stop very soon. I was surprised to be honest."
          </h4>
          <h6>
          – Local pharmacist in Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "Update: My hockey friend that I bought Cravv for, used it, and is now 10 days smoke free! In the past he had some nasty negative side effects on Champix - really bad vivid dreams, sleep disturbances and had to stop it. Had no success with quitting using nicotine replacement therapy. On Cravv, he had no vivid dreams and his only complaint was a bit of restlessness before bed but only very minor."
          </h4>
          <h6>
          – Bryce from Waterloo, Ontario
          </h6>
        </div>
      </div>
      <div class="container">
        <div class="colx1 center">
          <h4>
          "Started your program in October. Currently smoked 20-22 cigarettes per day. My first day without a cigarette was rememberance day. I have had little to none withdrawl effects, cravings, or irritability at this point. Keep in mind I have smoked off and on for more than 40 years. Your product made it very easy to get to where I wanted to."
          </h4>
          <h6>
          – Jeff from Kitchener, Ontario
          </h6>
        </div>
      </div>
    </div>
  </Layout>
)

export default Reviews
